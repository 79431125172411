// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/DDIN.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/DDIN.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/DDIN.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/DDINBold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/DDINBold.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/DDINBold.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/DINAlternateBold.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../fonts/DINAlternateBold.woff2");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../fonts/DINAlternateBold.woff");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../fonts/Mulish-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../fonts/mulish-regular.woff");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../fonts/Mulish-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_12___ = require("../fonts/mulish-semibold.woff");
var ___CSS_LOADER_URL_IMPORT_13___ = require("../fonts/Mulish-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_14___ = require("../fonts/mulish-bold.woff");
var ___CSS_LOADER_URL_IMPORT_15___ = require("../fonts/GillSans-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_16___ = require("../fonts/EngraversMT.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_16___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Din;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\")}@font-face{font-family:Din-Bold;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}@font-face{font-family:Din-Black;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff\")}@font-face{font-family:Mulish-Regular;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff\")}@font-face{font-family:Mulish-Semi;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"woff\")}@font-face{font-family:Mulish-Bold;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format(\"woff\")}@font-face{font-family:GillSans-Semi;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_15___ + ") format(\"truetype\")}@font-face{font-family:Engravers-MT;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_16___ + ") format(\"truetype\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
